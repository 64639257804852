<template>

  <div id="product-page">

    <v-row>
      <v-col class="pa-8"></v-col>
    </v-row>

    <v-row>
      <v-col class="pa-8"></v-col>
    </v-row>

    <v-row>
      <v-col class="pa-8"></v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card elevation="15" tile min-height="350">
          <div class="d-none d-sm-flex">
            <v-container class="pa-10">
              <app-health-care v-if="$route.params.product == 'healthCare'"/>
              <app-group-insurance v-else-if="$route.params.product == 'groupInsurance'"/>
            </v-container>
          </div>
          <div class="d-flex d-sm-none">
            <v-container>
              <app-health-care v-if="$route.params.product == 'healthCare'"/>
              <app-group-insurance v-else-if="$route.params.product == 'groupInsurance'"/>
            </v-container>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pa-10"></v-col>
    </v-row>

  </div>

</template>

<script>
export default {
  name: "ProductsPage",
}
</script>

<style scoped>

</style>